#sidebar-menu {
  padding: 30px 0 30px 0 !important;
}

.logo {
  padding: 10px 0 0;
}

.table {
  white-space: nowrap;
}

.btn-add {
  padding: 8px 10px !important;
  font-size: 14px !important;
  width: 100%;
}

.col-form-label span {
  padding-left: 15px;
}

.btn-view {
  margin-right: 12px;
}

#tech-companies-1 thead tr th,
#tech-companies-1 tbody tr td {
  width: 20%;
  vertical-align: middle;
}

#tech-companies-1 thead tr th:nth-child(1),
#tech-companies-1 tbody tr td:nth-child(1) {
  width: 6%;
}

.btn-view,
.btn-edit,
.btn-print,
.btn-delete {
  width: 40px;
  height: 40px;
  border-radius: 4px;
  margin: 5px;
}

.para1 {
  font-size: 15px;
  color: #2b2e31;
  line-height: 27px;
}

.para2 {
  font-size: 18px;
  color: #000;
  font-weight: 500;
}

.border-1 {
  border-bottom: 1px solid #cccccc4d;
  padding: 10px 0;
}

.btn-view .bx,
.btn-edit .bx,
.btn-print .bx,
.btn-delete .bx {
  font-size: 18px;
  transform: translate(0, 5px);
}

.m-auto {
  margin: auto !important;
}

.btn-view {
  background-color: rgb(52 195 143 / 13%) !important;
  border-color: transparent !important;
  color: #34c38f !important;
}

.btn-view:hover {
  background-color: #34c38f !important;
  border-color: transparent !important;
  color: #fff !important;
}

.btn-edit {
  background-color: #4285f421 !important;
  border: transparent !important;
  color: #556ee6 !important;
}

.btn-edit:hover {
  background-color: #556ee6 !important;
  border: transparent !important;
  color: #fff !important;
}

.btn-print {
  background-color: #5bc0de2e !important;
  border: transparent !important;
  color: #5bc0de !important;
}

.btn-print:hover {
  background-color: #5bc0de !important;
  border: transparent !important;
  color: #fff !important;
}

.btn-delete {
  background-color: #ff0e0e2b !important;
  border: transparent !important;
  color: #ff0e0e !important;
}

.btn-delete:hover {
  background-color: #ff0e0e !important;
  border: transparent !important;
  color: #fff !important;
}

.pagination {
  list-style: none;
  display: inline-block;
  padding: 0;
  margin-top: 10px;
}

.pagination li {
  display: inline;
  text-align: center;
}

.pagination a {
  float: left;
  display: block;
  font-size: 14px;
  text-decoration: none;
  padding: 5px 12px;
  color: #fff;
  margin-left: -1px;
  border: 1px solid transparent;
  line-height: 1.5;
}

.pagination a.active {
  cursor: default;
}

.pagination a:active {
  outline: none;
}

.modal-1 li:first-child a {
  -moz-border-radius: 6px 0 0 6px;
  -webkit-border-radius: 6px;
  border-radius: 6px 0 0 6px;
}

.modal-1 li:last-child a {
  -moz-border-radius: 0 6px 6px 0;
  -webkit-border-radius: 0;
  border-radius: 0 6px 6px 0;
}

.modal-1 a {
  border-color: #ddd;
  color: #4285f4;
  background: #fff;
}

.modal-1 a:hover {
  background: #eee;
}

.modal-1 a.active,
.modal-1 a:active {
  border-color: #4285f4;
  background: #4285f4;
  color: #fff;
}

/* .btn-save{
    margin-top: 34px;
  } */
.tooltip1 {
  position: relative;
  display: inline-block;
}

.tooltip1 .tooltiptext {
  visibility: hidden;
  width: 70px;
  font-size: 12px;
  background-color: #e0e0e7;
  color: #00000061;
  text-align: center;
  border-radius: 6px;
  padding: 4px 0;
  position: absolute;
  z-index: 1;
  top: 31px;
  left: -35px;
}

.tooltip1:hover .tooltiptext {
  visibility: visible;
}

/* Preloader */
.container-preloader {
  align-items: center;
  cursor: none;
  display: flex;
  height: 100%;
  justify-content: center;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 900;
}

.container-preloader .animation-preloader {
  position: absolute;
  z-index: 100;
}

/* Spinner Loading */
.container-preloader .animation-preloader .spinner {
  animation: spinner 1s infinite linear;
  border-radius: 50%;
  border: 3px solid rgba(0, 0, 0, 0.2);
  border-top-color: #556ee6;
  /* It is not in alphabetical order so that you do not overwrite it */
  height: 3em;
  margin: 0 auto 3.5em auto;
  width: 3em;
}

/* Animation of the preloader */
@keyframes spinner {
  to {
    transform: rotateZ(360deg);
  }
}

/* Animation of letters loading from the preloader */
@keyframes characters {

  0%,
  75%,
  100% {
    opacity: 0;
    transform: rotateY(-90deg);
  }

  25%,
  50% {
    opacity: 1;
    transform: rotateY(0deg);
  }
}

/* Laptop size back (laptop, tablet, cell phone) */
@media screen and (max-width: 767px) {

  /* Preloader */
  /* Spinner Loading */
  .container-preloader .animation-preloader .spinner {
    height: 5em;
    width: 5em;
  }

  /* Text Loading */
  .container-preloader .animation-preloader .txt-loading {
    font: bold 3.5em "Montserrat", sans-serif;
  }
}

@media screen and (max-width: 500px) {

  /* Prelaoder */
  /* Spinner Loading */
  .container-preloader .animation-preloader .spinner {
    height: 5em;
    width: 5em;
  }

  /*Loading text */
  .container-preloader .animation-preloader .txt-loading {
    font: bold 2em "Montserrat", sans-serif;
  }
}

.origin {
  text-decoration: none;
  font-size: 45px;
}

.invoice-details-box {
  background: #fff;
  border: 1px solid #e5e5e5;
  border-radius: 10px;
}

@media only screen and (max-width: 991.98px) {
  .invoice-inner-head {
    padding: 10px;
    border-bottom: 1px solid #e5e5e5;
  }
}

.invoice-inner-footer {
  padding: 0;
}

.invoice-inner-head {
  padding: 26px;
  border-bottom: 1px solid #e5e5e5;
}

@media only screen and (max-width: 991.98px) {
  .invoice-inner-date {
    padding: 10px;
  }
}

.invoice-inner-date {
  padding: 20px 15px;
}

.border-2 {
  border-right: 1px solid #e5e5e5;
}

.invoice-info {
  margin-bottom: 20px;
}

.invoice-item .customer-text {
  font-size: 20px;
  color: #000;
  font-weight: 600;
  margin-bottom: 16px;
  display: block;
}

.invoice-item .invoice-details-two {
  text-align: left;
  color: #000;
}

.invoice-item .customer-text a {
  font-size: 14px;
  color: #9a55ff;
  margin-left: 12px;
}

.invoice-add-table {
  padding-top: 20px;
  padding-bottom: 30px;
}

.payment-details {
  width: 266px;
  border-radius: 4px;
  padding: 15px;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.payment-details p {
  color: #fff;
  max-width: 140px;
  font-weight: 400;
  margin-bottom: 0;
}

.payment-details p {
  color: #fff;
  max-width: 140px;
  font-weight: 400;
  margin-bottom: 0;
}

.invoice-total-box {
  /* background: #fff;
  border: 1px solid #e5e5e5;
  border-radius: 4px; */
}

.invoice-total-inner {
  padding: 15px;
}

.invoice-total-footer {
  /* border-top: 1px solid #e5e5e5;
  padding: 15px 0; */
  color: #1b2559;
  margin-bottom: 15px;
  position: relative;
  border: 1px solid #000;
  padding: 0 12px;
  height: 52px;
  width: 100%;
}

.service-upload {
  justify-content: center !important;
  /* justify-content: flex-end; */
  /* display: flex; */
  display: flex !important;
  margin: 0 0 0 auto !important;
  text-align: center !important;
}

.invoice-total-box p {
  color: #272829;
  margin-bottom: 15px;
  position: relative;
  border: 1px solid #000;
  padding: 0 12px;
  font-size: 18px !important;
  font-weight: 500 !important;
  height: 52px;
  line-height: 50px;
  width: 100%;
}

.invoice-total-footer h4 {
  font-size: 20px;
  margin-bottom: 0;
  color: #556ee6;
  line-height: 50px;

}

.invoice-total-footer h4 span {
  float: right;
}

.invoice-total-box p span {
  float: right;
}

.service-upload input[type="file"] {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

.service-upload {
  border: 2px dashed #e5e5e5;
  text-align: center;
  padding: 30px 0;
  background-color: #fff;
  position: relative;
  width: 330px;
}

.img-remove {
  position: absolute;

  left: -48px;
  position: absolute;
  top: 14px;
  background: transparent;
  border: none;
}

.invoicedetails {
  padding: 30px;
}

.invoice-inner-footer span {
  font-weight: 500;
  color: #1b2559;
  position: relative;
  display: flex;
}

.invoice-inner-footer span {
  font-weight: 500;
  color: #1b2559;
  position: relative;
}

/* .invoice-inner-footer .form-control {
    position: relative;
    color: #9a55ff;
    background: 0 0;
    border: 0;
    display: inline-block;
    width: 120px;
    height: auto;
    padding: 0 10px;
}
.invoice-inner-footer .form-control {
    position: absolute;
    color: #9a55ff;
    background: 0 0;
    border: 0;
    display: inline-block;
    width: auto;
    height: auto;
    padding: 0 10px;
} */
.invoices-form .form-control {
  height: 50px;
  border: 1px solid #e5e5e5;
  border-radius: 6px;
}

#tech-companies-2 th,
#tech-companies-2 td {
  width: 16%;
}

.vertical-menu {
  background-color: #2a3042 !important;
}

.mm-active {
  color: #ffff !important;
}

.mm-active>a {
  color: #fff !important;
}

.mm-active>a>span,
.mm-active>a>i {
  color: #fff !important;
}

.mm-active a.active {
  color: #fff !important;
}

#sidebar-menu ul li a {
  color: #a6b0cf !important;
}

.mm-active .active i {
  color: #fff !important;
}

#sidebar-menu ul li ul.sub-menu li.mm-active a.active {
  color: #fff !important;
}

/* #sidebar-menu ul li a {
    color: #a6b0cf !important;
}
 .mm-active,  .mm-active .active, .mm-active .active i, .mm-active>a, .mm-active>a i,.vertical-collpsed .vertical-menu #sidebar-menu ul li.mm-active .active i {
    color: #fff!important;
}
.mm-active {
    color: #ffffff !important;
}

.mm-active li a{
    color: #ffffff !important;
} */
.nav-farmer {
  width: 50% !important;
}

.logo-lg img,
.logo-sm img {
  border-radius: 50%;
  border: 2px solid #2a3042;
}

body[data-sidebar="dark"] #sidebar-menu ul li a:hover span {
  color: #ffffff;
}

body[data-sidebar="dark"] #sidebar-menu ul li a:hover i {
  color: #ffffff;
}

.col-form-label {
  white-space: nowrap;
}

.h2_label {
  font-size: 15px;
  font-weight: 600;
  padding: 0 1px;
  align-items: center;
  display: flex;
}

.invoice_img {
  padding-bottom: 10px;
}

.navbar-brand-box img {
  padding: 2px 0;
}

.btn-download {
  position: absolute;
  top: -23px;
  right: -11px;
  color: #4285f4 !important;
  font-size: 14px !important;
}

.position-relative {
  position: relative;
}

.monthly-title span {
  width: 20%;
  vertical-align: middle;
  /* justify-content: center; */
  display: flex;
  align-items: center;
}

.monthly-title .form-control {
  width: 32% !important;
}

.profile-name {
  padding: 0 20px;
}

.invoice-total-inner1 .form-control {
  width: 395px !important;
}

.close-td {
  vertical-align: middle;
  text-align: center;
  font-size: 22px;
  color: #556ee6;
}

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 50%;
}

.nodata {
  text-align: center;
  font-size: 15px;
  font-weight: 500;
}

.css-1t1j96h-MuiPaper-root-MuiDialog-paper {
  width: 400px;
}

.MuiDialogContent-root {
  border-top: 1px solid #00000042;
  border-bottom: 1px solid #00000042;
}

.MuiDialogTitle-root+.css-ypiqx9-MuiDialogContent-root {
  padding-top: 20px !important;
}

#tech-companies-1 td {
  vertical-align: middle;
}

.navtab-info {
  width: 60%;
}

.navtab-info .nav-item,
.navtab-profile .nav-item {
  border: 1px solid #e0e0e7;
  margin-right: 2px;
  border-radius: 0.25rem;
}

.navtab-info .nav-item .active {
  border: 1px solid #4285f4;
  border-radius: 0.25rem;
}

.navtab-profile .nav-item .active {
  border: 1px solid #4285f4;
  border-radius: 0.25rem;
}

.navtab-info .nav-pills .nav-link.active,
.navtab-info .nav-pills .show>.nav-link {
  border: 1px solid #4285f4;
  border-radius: 0.25rem;
}

.navtab-profile .nav-pills .nav-link.active,
.navtab-profile .nav-pills .show>.nav-link {
  border: 1px solid #4285f4;
  border-radius: 0.25rem;
}

@media (max-width: 768px) {
  .invoice-total-box p span {
    float: right;
    text-align: right;
  }

  .invoice-total-box p {
    width: 100%;
    padding: 0;
  }

  .invoice-total-box p span {
    padding: 12px;
  }

  .invoice-total-box p .total-right {
    float: right;

    width: 50%;
    text-align: right;
    border-left: 1px solid #000;
  }

  .footer {
    font-size: 12px;
    text-align: center;
  }

  .add-table-items {
    width: 800px !important;
  }

  .navtab-bg {
    width: 100% !important;
  }


  .invoice-total-inner1 .form-control {
    width: 100% !important;
  }

  .invoice-total-inner1 {
    display: flex;
    flex-direction: column;
  }

  .invoice-total-box p span {
    float: none;
  }

  .invoice-total-box p {
    margin-bottom: 0.75rem;
  }

  .invoice-total-footer h4 span {
    float: none;
  }

  .view-invoice-total-inner p span {
    float: right !important;
  }

  .view-invoice-total-footer h4 span {
    float: right !important;
  }

  .invoice-inner-footer {
    margin-bottom: 0.75rem;
  }

  .service-upload {
    width: 100%;
  }

  .invoice-inner-footer h4 {
    display: grid;
    gap: 0.3rem;
  }

  .invoice-total-inner1 {
    width: 100%;
  }

  .invoice-total-inner {
    display: flex;
    flex-direction: column;
  }

  .monthly-title {
    display: flex !important;
    flex-direction: column !important;
  }

  .monthly-title span {
    width: 100%;
    margin-bottom: 1rem;
  }

  .monthly-title .form-control {
    width: 100% !important;
  }

  .nav-tabs-custom {
    width: 100% !important;
  }

  .btn-col {
    padding: 0 !important;
  }

  .card-body {
    padding: 1rem;
  }
}

.btn-col .btn {
  width: 100%;
}

@media (max-width: 860px) {
  .btn-col {
    padding: 0 !important;
  }
}

.css-12j7rf6-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
  color: #fff !important;
  background-color: #4285f4 !important;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.pl-1 {
  padding-left: 0.25rem;
}

.pr-1 {
  padding-right: 0.25rem;
}

.spinner {
  position: absolute;
  top: 45%;
  left: 50%;
}

@media print {
  .card-invoice {
    height: 70%;
  }

  .invoice-body {
    width: 50%;
    padding: 0 !important;
  }

  .invoice-total-box p {
    font-size: 15px;
  }

  .invoice-total-footer h4 {
    font-size: 18px;
  }

  .printview {
    position: relative;
    width: 250px;
    height: 40px;
    margin-bottom: 10px;
    margin-top: 10px;
    text-align: right;
    left: 450px;
  }
}

.css-1bnedkx.Mui-selected {
  color: rgb(255, 255, 255);
  background-color: rgb(85 110 230) !important;
}

.navbar-brand-box {
  background: #2a3042;
}

@media (min-width:768px) {
  .filter-button {
    display: flex;
    align-items: center;


  }
}

.card-invoice {
  position: relative;
}

.side-imgg {
  height: 100%;

}

.invoice-img {
  width: 3%;

}

.invoice-body {
  width: 97%;
  padding: 0 !important;
}

.card-invoice {
  width: 100%;
  flex-direction: row !important;
}

.card-invoice-heading {
  font-size: 55px;
  font-weight: 600;
  color: #ff6e00;
  letter-spacing: 1px;
  margin-bottom: 15px;
}

.card-invoice-add {
  font-size: 26px;
}

.card-invoice-phone {
  font-size: 17px;
  color: #777;
  margin-top: 8px;
}

.card-invoice-head {
  padding: 10px 1.8rem;
  position: relative;
  border-bottom:2px solid #00be00 ;
}

/* .card-invoice-head::after {
  content: "";
  position: absolute;
  background-color: #00be00;
  width: 100%;
  left: 0;
  bottom: 5px;
  right: 0;
  height: 3px;
} */

.invoice-form-detail {
  padding: 24px 30px !important;
}

/* 
.invoice-footer {
  padding: 35px 10px !important;
} */

.table-invoice thead {
  background: #00be00;
  color: #fff;
}

.table-invoice {
  border: 1px solid #000 !important;
}

.table-invoice thead tr th,
.table-invoice tbody tr td {
  padding: 0.85rem 0.75rem;
  text-align: center !important;
  border: 1px solid #000 !important;
}

/* .invoice-total-box p:after, */
/* .invoice-total-footer:after {
  content: "";
  position: absolute;
  left: 50%;
  background: #000;
  width: 1px;
  top: 0;
  height: 100%;
} */

.sign-p {
  /* text-align: center; */
  font-size: 20px;
  font-weight: 600;
  padding: 5px 0 10px
}

.card-invoice-add {
  margin-bottom: 0
}

.card-invoice-label {
  position: relative;
  margin: 27px auto 20px;
  padding: 15px 40px;
  text-align: center;
  background-color: #ff6e00;
}

.card-invoice-label::before,
.card-invoice-label::after {
  content: '';
  width: 85px;
  height: 100%;
  background-color: #ff6e00;
  position: absolute;
  /* z-index: -1; */
  top: 0;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%, 25% 50%);
  background-image: linear-gradient(45deg, #ff6e00 50%, #ff6e00 50%);
  background-size: 20px 20px;
  background-repeat: no-repeat;
  background-position: bottom right;
}

.card-invoice-label::before {
  left: -32px;
}

.card-invoice-label::after {
  right: -32px;
  transform: scaleX(-1);
  /* flip horizontally */
}

.card-invoice-label .card-invoice-add {
  color: #fff;
}

/* .card-invoice {
  height: 78% !important;
} */

@media only screen and (max-width: 1300px) {
  .card-invoice-img {
    width: 40%;
  }

  .invoice-card-note-part,
  .card-footer-part,
  .img-row,
  .print-btn,
  .card-rules {
    padding: 0.6rem 2rem !important
  }

  .card-invoice-heading {
    font-size: 36px;
  }

  .card-invoice-add {
    font-size: 17px;
  }

  .card-invoice-img {
    width: 50%;
  }

  .card-invoice-img img {
    width: 100%;
    height: 60px;
  }

  .invoice-card-note .col-md-7 {
    width: 50% !important
  }

  .invoice-card-note .col-md-5 {
    width: 50% !important;
  }
}

.card-invoice-img {
  margin-top: 10px;
}

.sign-p {
  text-align: right;
}

.invoice-add-table {
  padding-left: 1.8rem;
  padding-right: 1.8rem;
}



.card-invoice-img {
  margin: 20px auto;
  justify-content: center;
  align-items: center;
}

.notes-label {
  color: #000;
  font-weight: 600;
  font-size: 17px;
}

.h2_label {
  font-size: 16px;
  font-weight: 600;
}

.table-invoice thead tr th {
  background-color: #00be00 !important;
}

@media only screen and (min-width:320px) and (max-width:768px) {
  .invoice-total-box p{
    font-size: 15px !important;
  }
  .para2 {
    font-size: 14px;
    color: #000;
    font-weight: 500;
}
  .card-invoice-head .row {
    width: 100%;
    display: flex;
    flex-direction: column;

  }

  .side-imgg {
    display: none;
  }

  .card-invoice-head .row .col-md-3 {
    width: 100% !important;
    margin: auto;
  }

  .card-invoice-head .row .col-md-3 img {
    width: 60px !important;
    height: 60px !important;
  }

  .card-invoice-head .row .col-md-9 {
    width: 100%;
  }

  .card-invoice-heading {
    font-size: 21px;
    line-height: 30px;
    margin-bottom: 4px;
  }

  .card-invoice-add {
    font-size: 15px !important;
    line-height: 28px !important;
  }

  .card-invoice-phone {
    font-size: 15px !important;
    color: #777;
    margin-top: 4px !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .card-invoice-phone img {
    width: 15px;
    height: 15px;
    margin-right: 4px;
    margin-top: auto;
    margin-bottom: auto;
  }

  .img-row {
    width: 100%;
  }

  .img-row .col-md-3 {
    width: 100% !important;
    margin: auto !important;
  }

  .card-invoice-img img {
    width: 80% !important;
    height: 50px !important;
  }

  .invoice-footer {
    display: block !important
  }

  .invoice-footer .col-md-6 {
    width: 100% !important;
  }
  .selectedimg img{
    width:100px !important
  }
  .sign-p {
    font-size: 15px;
    text-align: right;
  }

  .card-invoice {
    height: inherit !important;
  }

  .invoice-form-detail {
    display: block !important
  }

  .invoice-form-detail .col-md-9 {
    width: 100% !important;
    padding: 0
  }

  .invoice-form-detail .col-md-3 {
    width: 100% !important;
    padding: 0
  }

  .invoice-add-table {
    padding: 0;
  }

  .invoice-card-note {
    display: block !important;
  }

  .invoice-card-note .col-md-6 {
    width: 100% !important
  }

  .invoice-card-note .col-md-6 {
    width: 100% !important
  }

  .invoice-total-inner p span {
    padding: 0;
  }

  .invoice-total-inner p {
    padding:0 12px;
  }

  .invoice-total-inner {
    padding: 0
  }

  .invoice-card-note-part {
    padding-top: 10px;
  }

  .invoice-card-note-part,
  .card-footer-part,
  .img-row,
  .print-btn,
  .card-invoice-head,
  .card-rules {
    padding: 0.6rem 0 !important;
  }

  .print-btn {
    width: 100%;
  }
}

@media only screen and (min-width:600px) and (max-width:768px) {
  .card-invoice-img img {
    height: 60px;
    width: 60% !important;
  }

  .invoice-form-detail {
    display: flex !important
  }

  .invoice-form-detail .col-md-9 {
    width: 60% !important
  }

  .invoice-form-detail .col-md-3 {
    width: 40% !important
  }

  .sign-p {
    text-align: right;
  }

  .invoice-footer {
    display: flex !important;
  }

  .invoice-footer .col-md-6 {
    width: 50% !important;
  }
}

.card-invoice {
  height: 75%;
}

.invoice-card-note-part,
.card-footer-part,
.img-row,
.print-btn,
.card-rules {
  padding: 0.6rem 1.8rem;
}

.invoice-footer img {
  height: 55px !important;
}

.invoice-total-box p span {
  float: right;
  width: 50%;
  text-align: right;
  border-left: 1px solid #000;
}

.invoice-total-footer h4 span {
  float: right;
  width: 50%;
  text-align: right;
  border-left: 1px solid #000;
}
.card-footer-part{
  border-top: 2px solid #00be00;
}
@media print {
  /* Set page margins for all devices */
  @page {
      margin: 0cm;
      size: A4 portrait;
      margin:0 !important;
      size: auto /* Adjust margin as needed */
  }

  /* Set page size for mobile devices when printing */
  @media only screen and (max-width: 767px) {

      @media print {
        @page {
          size: A4 portrait; /* Set page size, e.g., A4 portrait, A4 landscape, etc. */
          margin:0cm; /* Adjust margin as needed for mobile printing */
          padding: 0cm;
          size: auto;
           margin:0 !important;
          padding: 0 !important; 
         
      }
     
     body,html{
      margin:0 !important;
      padding: 0 !important;
      height:1122.2px !important;
     }
     .card-invoice {
      margin: 0 !important;
      padding: 0 !important;
      height: 1122.2px !important;
    }
  
    .page-content {
      padding: 0 !important;
      margin: 0 !important;
      width: 100% !important;
     
    }
  
    .layout-wrapper {
      padding: 0 !important;
      margin: 0 !important;
      width: 100% !important;
    
    }
  
    .main-content {
      padding: 0 !important;
      margin: 0 !important;
      width: 100% !important;
      height: 1122.2px  !important;
      overflow: hidden !important;
    }
    .invoice-body{
      height: 1122.2px !important;
    }
  
  
  }
}

  /* Any other print-specific styles go here */
}
@media print {
  .card-invoice{
    overflow: hidden !important;
  }
  .invoice-img .side-imgg{
    height:1122.2px !important
  }
}
@media print {

  body {
    margin: 0 !important;
    padding: 0 !important;
  }
  /* body {
    display:table;
    table-layout:fixed;
    padding-top:2.5cm;
    padding-bottom:2.5cm;
    margin: 0 !important;
    height:auto;
     } */
  .card-invoice {
    margin: 0 !important;
    padding: 0 !important;
    height: 1122.2px !important;
  }

  .page-content {
    padding: 0 !important;
    margin: 0 !important;
    width: 100% !important;
  }

  .layout-wrapper {
    padding: 0 !important;
    margin: 0 !important;
    width: 100% !important;
  }

  .main-content {
    padding: 0 !important;
    margin: 0 !important;
    width: 100% !important;
    overflow: hidden !important;
  }

  .invoice-body {
    padding: 0 !important;
    margin: 0 !important;
    width: 100% !important;
    height: 1122.2px !important;
  }

  .container-fluid {
    padding: 0 !important;
    margin: 0 !important;
  }

  body {
    margin: 0 !important;
    padding: 0 !important;

  }

  .card-body {
    padding: 0 !important;

  }

  .container-fluid {
    padding: 0 !important;

  }

  .page-content {
    padding: 0 !important;
    margin: 0 !important;

  }

  .layout-wrapper {
    padding: 0 !important;
    margin: 0 !important;
    width: 100% !important;

  }

  .main-content {
    padding: 0 !important;
    margin: 0 !important;
    width: 100% !important;
    overflow: hidden !important;

  }
  .card-invoice-img {
    margin-top: 30px !important;
  }
  .invoice-body {
    width: 97%;
    padding: 0.6rem 0 0.6rem 2rem !important;
    overflow-y: hidden;
    overflow-x: hidden;
  }
  .card {
    height: 100%;
    margin-bottom: 0;
    box-shadow: 0;
  }
  .img-row {
    width: 100%;
    padding: 0 !important;
  }
  .table-invoice thead,
  .card-invoice-phone {
    -webkit-print-color-adjust: exact;
    /* Chrome, Safari */
    color-adjust: exact;
    print-color-adjust: exact;
  }

  .table-invoice thead th {
    -webkit-print-color-adjust: exact;
    /* Chrome, Safari */
    color-adjust: exact;
    print-color-adjust: exact;
  }

  .invoice-total-inner p span {
    width: 50%;
    border: 0;
    border-left: 1px solid #000;
    text-align: right;
  }

  .invoice-total-inner p {
    width: 100%;
    padding: 0 12px;
    height: 50px;
    line-height: 50px;
  }

  .invoice-add-table {
    padding-left: 1.8rem !important;
    padding-right: 1.8rem !important;
  }

  .card {
    overflow-y: hidden;
  }
  .selectedimg img {
    justify-content: end;
    display: flex;
    margin-right: 0;
    text-align: right;
    position: absolute;
    content: "";
    right: 0;
    left: 100%;
    top: 0;
  }

  .selectedimg {
    height: 40px;
    position: relative;
    justify-content: end;
    display: flex;
    width: 100px;
    margin-right: 0;
    text-align: right;
  }

  .invoice-footer {
    width: 100%;
    display: flex;
  }

  .sign-p {
    text-align: right;
  }

  .card-invoice-heading {
    font-size: 12px;
  }

  .card-invoice-img {
    margin-top: 10px;
  }

  .invoice-add-table {
    padding-left: 1.8rem !important;
    padding-right: 1.8rem !important;
  }

  .card-invoice-img img {
    width: 250px;
    margin: auto;
  }

  .footer-heading .card-invoice-heading {
    font-size: 50px;
  }

  .table-invoice thead {
    background-color: #00be00 !important;
    color: #fff;
  }

  .table-invoice thead tr th {
    background-color: #00be00 !important;
    color: #fff;
  }

  .table-invoice {
    border: 1px solid #000 !important;
  }

  .table-invoice thead tr th,
  .table-invoice tbody tr td {
    padding: 0.85rem 0.75rem;
    text-align: center !important;
    border: 1px solid #000 !important;
  }

  .table-invoice {
    width: 100% !important;
  }

  .card-invoice-heading {
    font-size: 30px;
    margin-top: 12px;
  }

  .card-invoice-head::after {
    content: "";
    position: absolute;
    background-color: #00be00;
    width: 100%;
    left: 0;
    bottom: 5px;
    right: 0;
    height: 3px;
  }

  .card-invoice-add {
    margin-bottom: 0;
  }

  .card-invoice-label {
    position: relative;
    margin: 27px auto 20px;
    padding: 15px 40px;
    text-align: center;
    background-color: #ff6e00;
  }

  .card-invoice-label::before,
  .card-invoice-label::after {
    content: '';
    width: 85px;
    height: 100%;
    background-color: #ff6e00;
    position: absolute;
    /* z-index: -1; */
    top: 0;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%, 25% 50%);
    background-image: linear-gradient(45deg, #ff6e00 50%, #ff6e00 50%);
    background-size: 20px 20px;
    background-repeat: no-repeat;
    background-position: bottom right;
  }

  .card-invoice-add {
    font-size: 16px;
  }

  .card-invoice-phone {
    font-size: 15px;
    margin-top: 13px;
  }

  .card-invoice-img {
    margin: 20px auto;
    justify-content: center;
    align-items: center;
  }

  .para2 {
    font-size: 17px;
    color: #000;
    font-weight: 500;
    margin-bottom: 30px;
  }

}

.form-control:disabled{
  background-color: #fff !important;

}
.invoice-total-inner .d-flex{
  height: 52px;
}
.invoice-total-inner input{
  margin: auto;
}

.invoice-total-inner1 p span{
width: auto !important;
padding: 7px;
}